import React from 'react'
import PropTypes from 'prop-types'

import { withTranslation } from 'react-i18next'

import { Paper, Button, CircularProgress } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import ResultCard from './ResultCard'

import './ResultList.css'

const ResultList = (props) => {
  const remainingArticles = props.totalArticles - props.articles.length
  const { isAuthorized, aboShopUrl } = props

  const nextLoadAmount =
    remainingArticles >= props.loadMoreSize
      ? props.loadMoreSize
      : remainingArticles

  const loadMoreButton = (
    <Button
      className='largeGutter'
      variant='contained'
      color='primary'
      onClick={props.onLoadMore}
      disabled={props.loading}
      endIcon={<ExpandMoreIcon />}
      fullWidth
    >
      {props.loading ? (
        <CircularProgress size={20} />
      ) : (
        <span>{props.t('resultList.loadMore')(nextLoadAmount)}</span>
      )}
    </Button>
  )

  return (
    <div>
      <Paper elevation={0}>
        {props.articles.map((article) => (
          <ResultCard
            key={article._source.id}
            article={article._source}
            isAuthorized={isAuthorized}
            aboShopUrl={aboShopUrl}
          />
        ))}
      </Paper>
      {props.articles.length > 0 &&
        props.articles.length < props.totalArticles &&
        loadMoreButton}
    </div>
  )
}

ResultList.propTypes = {
  articles: PropTypes.array.isRequired,
  totalArticles: PropTypes.number.isRequired,
  onLoadMore: PropTypes.func,
  loadMoreSize: PropTypes.number,
  loading: PropTypes.bool,
}

export default withTranslation()(ResultList)
