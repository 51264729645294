import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

i18n.use(initReactI18next).init({
  lng: 'en',
  keySeparator: '.',
  fallbackLng: 'en',
  interpolation: {
    // React already does escaping
    escapeValue: false,
  },
  // Using simple hardcoded resources for simple example
  resources: {
    en: {
      translation: {
        searchForm: {
          keywords: 'Keywords',
          start: 'Start',
          end: 'End',
          reset: 'Reset',
          search: 'Search',
          countResults: 'results',
          error: {
            invalidDateFormat: 'Invalid date format',
            beforeMinDate: (minDate) => `Date should not be before ${minDate}`,
            afterMaxDate: 'Date should not be in the future',
          },
        },
        resultList: {
          loadMore: (n) => `Load ${n} more results`,
          downloadPdf: 'Download PDF',
          noAccess: 'You need an active subscription to access this content.',
          noFile: 'There is no PDF file available for this content.',
          tryNow: 'Test 14 days for free now.',
        },
        error: {
          network: 'I could not reach the server',
          authentication: 'I could not confirm your authentication',
          generic: 'Something went wrong',
          retry: 'retry',
        },
      },
    },
    de: {
      translation: {
        searchForm: {
          keywords: 'Suchbegriffe',
          start: 'Beginn',
          end: 'Ende',
          reset: 'Zurücksetzen',
          search: 'Suchen',
          countResults: 'Resultate',
          error: {
            invalidDateFormat: 'Ungültiges Datumsformat',
            beforeMinDate: (minDate) =>
              `Datum darf nicht vor dem ${minDate} liegen`,
            afterMaxDate: 'Datum darf nicht in der Zukunft liegen',
          },
        },
        resultList: {
          loadMore: (n) => `${n} weitere Resultate laden`,
          downloadPdf: 'PDF laden',
          noAccess:
            'Sie brauchen ein gültiges Abonnement, um auf diese Inhalte zuzugreifen.',
          noFile: 'Zu diesem Resultat ist kein PDF verfügbar.',
          tryNow: 'Jetzt 14 Tage gratis testen.',
        },
        error: {
          network: 'Ich konnte den Server nicht erreichen.',
          authentication: 'Ich konnte Ihre Authentifizierung nicht bestätigen',
          generic: 'Etwas ist schiefgegangen',
          retry: 'Erneut versuchen',
        },
      },
    },
    fr: {
      translation: {
        searchForm: {
          keywords: 'Mots clés',
          start: 'Depuis le',
          end: "Jusqu'à",
          reset: 'Réinitialiser',
          search: 'Rechercher',
          countResults: 'résultats',
          error: {
            invalidDateFormat: 'Format de date non valide',
            beforeMinDate: (minDate) =>
              `Date ne devrait pas être avant le ${minDate}`,
            afterMaxDate: 'Date ne doit pas se situer dans le futur',
          },
        },
        resultList: {
          loadMore: (n) => `Charger ${n} autres résultats`,
          downloadPdf: 'Télécharger PDF',
          noAccess:
            "Vous avez besoin d'un abonnement actif pour accéder à ce contenu.",
          noFile: "Il n'y a pas de PDF disponible pour ce résultat.",
          tryNow: '14 jours de lecture gratuite.',
        },
        error: {
          network: 'Impossible de contacter le serveur',
          authentication: "Je n'ai pas pu confirmer votre authentification",
          generic: 'Quelque chose à mal tourné',
          retry: 'Réessayer',
        },
      },
    },
  },
})

export default i18n
