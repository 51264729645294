import React from 'react'
import PropTypes from 'prop-types'

import { withTranslation } from 'react-i18next'

import moment from 'moment'

import {
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  Link,
  Tooltip,
} from '@material-ui/core'

import './ResultCard.css'

const htmlTags = /<[^>]*>/g
const previewLength = 200

const DownloadButton = (props) => {
  const { isAuthorized, article, t, aboShopUrl } = props
  const { filePath } = article
  if (filePath) {
    return (
      <Button
        className='gutterTop'
        variant='contained'
        color='secondary'
        onClick={() => window.open(filePath, '_blank')}
        disabled={!filePath}
      >
        {t('resultList.downloadPdf')}
      </Button>
    )
  } else {
    return (
      !isAuthorized ? (
      <Tooltip
        title={
          <>
            {t('resultList.noAccess')} <strong><Link href={aboShopUrl} target="_blank" color="inherit">{t('resultList.tryNow')}</Link></strong>
          </>
          }
        placement='left'
        enterTouchDelay={0}
        interactive
      >
        <span>
          <Button variant='contained' color='secondary' disabled={true}>
            {t('resultList.downloadPdf')}
          </Button>
        </span>
      </Tooltip>
      ) : (
        <Tooltip
        title={t('resultList.noFile')}
        placement='left'
        enterTouchDelay={0}
      >
        <span>
          <Button variant='contained' color='secondary' disabled={true}>
            {t('resultList.downloadPdf')}
          </Button>
        </span>
      </Tooltip>
      )
    )
  }
}

const ResultCard = (props) => {
  const { article } = props
  const { title, authors, text, pubDateTime } = article

  return (
    <Card elevation={0} className='resultCard'>
      <CardContent>
        <Typography className='serifHeading' variant='h5' component='h2'>
          {title}
        </Typography>
        <Typography
          className='serifSubHeading'
          variant='subtitle2'
          color='textSecondary'
        >
          {authors.join(', ')}
        </Typography>
        <Typography className='serifContent' component='p' gutterBottom>
          {text
            .replace(htmlTags, ' ')
            .trim()
            .substring(0, previewLength) + '...'}
        </Typography>
        <Typography color='textSecondary' variant='caption'>
          {moment(pubDateTime).format('LL')}
        </Typography>
      </CardContent>
      <CardActions className='flushLeft'>
        <DownloadButton {...props}/>
      </CardActions>
    </Card>
  )
}

ResultCard.propTypes = {
  article: PropTypes.object.isRequired,
}

export default withTranslation()(ResultCard)
