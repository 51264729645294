import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import { Grid, Typography, Button } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";

const Error = props => {
  let message;
  if (!props.error.response) {
    message = props.t("error.network");
  } else if (props.error.response.status === 401) {
    message = props.t("error.authentication");
  } else {
    message = "Something went wrong!";
  }

  return (
    <Grid
      container
      justify="center"
      direction="column"
      alignItems="center"
      style={{ marginTop: "15vh" }}
    >
      <div>
        <WarningIcon style={{ fontSize: 60 }} color="error" />
      </div>
      <Typography variant="body1">Oops...{message}!</Typography>
      {props.onRetry && (
        <Button color="primary" onClick={() => props.onRetry()}>
          {props.t("error.retry")}
        </Button>
      )}
    </Grid>
  );
};

Error.propTypes = {
  error: PropTypes.object.isRequired,
  onRetry: PropTypes.func
};

export default withTranslation()(Error);
