const aboShopUrls = {
  TA: 'https://login.tagesanzeiger.ch/register/email',
  BAZ: 'https://login.bazonline.ch/register/email',
  BU: 'https://login.derbund.ch/register/email',
  BZ: 'https://login.bernerzeitung.ch/register/email',
  THT: 'https://login.thunertagblatt.ch/register/email',
  LAT: 'https://login.langenthalertagblatt.ch/register/email',
  BEOL: 'https://login.berneroberlaender.ch/register/email',
  ZSZ: 'https://login.zsz.ch/register/email',
  LB: 'https://login.landbote.ch/register/email',
  ZHUL: 'https://login.zuonline.ch/register/email',
  HEU: 'https://login.24heures.ch/register/email',
  TDG: 'https://login.tdg.ch/register/email',
}

export default aboShopUrls
