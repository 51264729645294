import React, { Component } from "react";
import PropTypes from "prop-types";

import { withTranslation } from "react-i18next";

import {
  Grid,
  TextField,
  Button,
  InputAdornment,
  CircularProgress,
  Typography
} from "@material-ui/core";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";

import MomentUtils from "@date-io/moment";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";

import "./SearchForm.css";

const initialState = {
  keywords: "",
  dateFrom: null,
  dateTo: null
};

const dateStyle = {
  variant: "inline",
  inputVariant: "filled",
  format: "DD.MM.YYYY",
  KeyboardButtonProps: {
    "aria-label": "change date"
  },
  disableToolbar: true,
  disableFuture: true,
  fullWidth: true,
  PopoverProps: {
    marginThreshold: 0,
    style: { fontSize: ".1em", margin: 0, padding: 0 }
  },
  minDate: "1996-01-01",
  autoOk: true
};

class SearchForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };
    this.customMessages = {
      invalidDateMessage: props.t("searchForm.error.invalidDateFormat"),
      minDateMessage: props.t("searchForm.error.beforeMinDate")(
        dateStyle.minDate
      ),
      maxDateMessage: props.t("searchForm.error.afterMaxDate")
    };
  }

  handleFormEvent = (value, stateName) => {
    this.setState({ [stateName]: value });
  };

  handleReset = () => {
    this.setState({ ...initialState });
    this.props.onReset();
  };

  handleSearch = () => {
    const searchQuery = {};
    if (this.state.keywords) searchQuery["keywords"] = this.state.keywords;
    if (this.state.dateFrom)
      searchQuery["dateFrom"] = this.state.dateFrom.format(dateStyle.format);
    if (this.state.dateTo)
      searchQuery["dateTo"] = this.state.dateTo.format(dateStyle.format);
    this.props.onSearch(searchQuery);
  };

  formIsInvalid = () => {
    if (this.state.dateFrom !== null) {
      if (!this.state.dateFrom.isValid()) return true;
      if (this.state.dateFrom.isBefore(dateStyle.minDate)) return true;
      if (this.state.dateFrom.isAfter()) return true;
    }
    if (this.state.dateTo !== null) {
      if (!this.state.dateTo.isValid()) return true;
      if (this.state.dateTo.isBefore(dateStyle.minDate)) return true;
      if (this.state.dateTo.isAfter()) return true;
    }
    if (this.state.dateFrom !== null && this.state.dateTo !== null) {
      if (this.state.dateFrom.isAfter(this.state.dateTo)) return true;
    }
    return false;
  };

  render() {
    return (
      <div>
        <Grid className="formMargin" container spacing={4}>
          <Grid item xs={12}>
            <TextField
              variant="filled"
              label={this.props.t("searchForm.keywords")}
              type="search"
              value={this.state.keywords}
              onChange={e => this.handleFormEvent(e.target.value, "keywords")}
              onKeyDown={e => (e.key === "Enter" ? this.handleSearch() : null)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchOutlinedIcon color="action" />
                  </InputAdornment>
                )
              }}
              fullWidth
            />
          </Grid>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Grid item sm={6} xs={12}>
              <KeyboardDatePicker
                {...dateStyle}
                {...this.customMessages}
                label={this.props.t("searchForm.start")}
                value={this.state.dateFrom}
                onChange={date => this.handleFormEvent(date, "dateFrom")}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <KeyboardDatePicker
                {...dateStyle}
                {...this.customMessages}
                label={this.props.t("searchForm.end")}
                value={this.state.dateTo}
                onChange={date => this.handleFormEvent(date, "dateTo")}
              />
            </Grid>
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid
          className="formMargin"
          container
          spacing={4}
          direction="row-reverse"
        >
          <Grid item xs={12} sm={"auto"}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={this.handleSearch}
              disabled={this.props.loading || this.formIsInvalid()}
              startIcon={
                this.props.loading ? (
                  <CircularProgress size={20} />
                ) : (
                  <SearchOutlinedIcon />
                )
              }
            >
              {this.props.t("searchForm.search")}
            </Button>
          </Grid>
          <Grid item xs={12} sm={"auto"}>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              onClick={this.handleReset}
            >
              {this.props.t("searchForm.reset")}
            </Button>
          </Grid>
          <Grid item className="grow">
            <Typography variant="subtitle1" component="span">
              <b>
                {this.props.totalArticles.toLocaleString() || 0}{" "}
                {this.props.t("searchForm.countResults")}
              </b>
            </Typography>
          </Grid>
        </Grid>
      </div>
    );
  }
}

SearchForm.propTypes = {
  onSearch: PropTypes.func,
  onReset: PropTypes.func,
  loading: PropTypes.bool,
  totalArticles: PropTypes.number
};

export default withTranslation()(SearchForm);
