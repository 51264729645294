import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'

import moment from 'moment'
import 'moment/locale/de'
import 'moment/locale/fr'

import queryString from 'query-string'
import { api } from './helpers/api'
import customTheme from './helpers/customTheme'

import { ThemeProvider } from '@material-ui/styles'
import { responsiveFontSizes } from '@material-ui/core/styles'

import SearchForm from './Components/SearchForm'
import ResultList from './Components/ResultList'
import Error from './Components/Error'

import aboShopUrls from './config/aboShopUrls'

import './App.css'

const defaultSearchSize = 30
const initialState = {
  results: [],
  total: 0,
  searchQuery: {},
  searching: false,
  loadingMore: false,
  error: null,
}

class App extends Component {
  constructor(props) {
    super(props)

    // Import required url parameters
    const { accent_color, auth_token, tenant, lang } = queryString.parse(
      window.location.search
    )

    this.theme = responsiveFontSizes(customTheme(accent_color))
    this.token = auth_token
    this.tenants = tenant ? tenant.split(',') : []
    this.lang = lang || 'en'

    // Configure language locales
    this.props.i18n.changeLanguage(this.lang)
    moment.locale(this.lang)

    this.state = { ...initialState }
  }

  componentDidMount() {
    this.handleSearch()
  }

  searchRequest = async (loadMore = false, from = 0) => {
    const urlPath = '/horton-archive-search'
    const searchParams = {
      from: from,
      size: defaultSearchSize,
      targets: this.tenants,
      sort: true,
      ...this.state.searchQuery,
    }
    const headers = {}
    if (this.token) headers['Authorization'] = 'Bearer '.concat(this.token)
    let response
    try {
      response = await api.post(urlPath, searchParams, { headers })
    } catch (error) {
      console.error(error)
      this.setState({
        error,
        searching: false,
        loadingMore: false,
      })
      return
    }

    const results = loadMore
      ? this.state.results.concat(response.data.results)
      : response.data.results
    const isAuthorized = response.data.isAuthorized
    const total = response.data.total
    this.setState({ results, total, isAuthorized })
    if (loadMore) {
      this.setState({ loadingMore: false })
    } else {
      this.setState({ searching: false })
    }
  }

  handleSearch = (searchQuery = {}) => {
    this.setState(
      { searchQuery, searching: true, error: null },
      this.searchRequest
    )
  }

  handleReset = () => {
    this.setState({ ...initialState }, this.handleSearch)
  }

  handleLoadMore = () => {
    this.setState({ loadingMore: true })
    this.searchRequest(true, this.state.results.length)
  }

  render() {
    if (this.state.error) {
      return (
        <ThemeProvider theme={this.theme}>
          <Error error={this.state.error} onRetry={this.handleSearch} />
        </ThemeProvider>
      )
    } else {
      return (
        <ThemeProvider theme={this.theme}>
          <SearchForm
            onSearch={this.handleSearch}
            onReset={this.handleReset}
            loading={this.state.searching}
            totalArticles={this.state.total}
          />
          <ResultList
            articles={this.state.results}
            totalArticles={this.state.total}
            onLoadMore={this.handleLoadMore}
            loadMoreSize={defaultSearchSize}
            loading={this.state.loadingMore}
            isAuthorized={this.state.isAuthorized}
            aboShopUrl={aboShopUrls[this.tenants[0]]}
          />
        </ThemeProvider>
      )
    }
  }
}

export default withTranslation()(App)
