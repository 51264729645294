import { createMuiTheme } from "@material-ui/core/styles";

const primaryColor = "#202346";
const secondaryColor = "#f2f0ea";
const defaultTenantColor = "#0a78bd";
const textFieldColor = "#f3f2ec";
const darkGray = "#555555";
//const lightGray = "#d4d4d4";

const customTheme = tenantColor =>
  createMuiTheme({
    palette: {
      primary: {
        main: primaryColor
      },
      secondary: {
        main: secondaryColor
      },
      light: {
        main: secondaryColor
      },
      tenant: {
        main:
          tenantColor !== undefined
            ? "#".concat(tenantColor)
            : defaultTenantColor
      },
      text: {
        primary: primaryColor,
        secondary: darkGray
      }
    },
    spacing: 5,
    shape: { borderRadius: 0 },
    typography: {
      fontFamily: ['"IBM Plex Sans"', "sans-serif"].join(","),
      subtitle2: { fontSize: "0.9375rem", fontWeight: "bold" },
      button: {
        fontWeight: 700
      },
      body2: { fontWeight: "unset" }
    },
    overrides: {
      MuiButton: {
        root: {
          padding: "5px 30px",
          minHeight: "40px",
          textTransform: "unset",
          "& span::first-letter": { textTransform: "capitalize" }
        },
        contained: {
          boxShadow: "none",
          "&:hover": { boxShadow: "none" }
        }
      },
      MuiInput: {
        underline: {
          "&::before": { borderBottom: "1px solid " + primaryColor },
          "&&&&:hover:before": {
            borderBottom: "1px solid " + primaryColor
          }
        }
      },
      MuiFilledInput: {
        root: {
          backgroundColor: textFieldColor,
          "&:hover": { backgroundColor: "none" },
          "&$focused": {
            backgroundColor: "none"
          }
        }
      },
      MuiPopover: { paper: { maxWidth: "calc(100% - 0px)" } },
      MuiPickersDay: {
        day: { width: "34px", height: "34px" },
        dayLabel: { width: "34px" }
      },
      MuiPickersBasePicker: {
        pickerView: { minWidth: "280px", minHeight: "300px" }
      },
      MuiPickersCalendar: { transitionContainer: { minHeight: "200px" } }
    }
  });

export default customTheme;
